@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  margin: 0px;
  padding: 0px;
  text-decoration: none;
  outline: none;
  border: 0px;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.5px;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}
.file-name {
  visibility: hidden;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  color: black;
  padding: 5px;
  bottom: 0;
  left: 50%;
  right: 50%;
  text-align: center;
  transition: visibility 0.2s;
}

.login-bg{
  background-image: url('https://beta.autoworks.africa/storage/car-parts-1-1.jpg');
  background-position: center;
  background-size: cover;
}

html {
  scroll-behavior: smooth;
}

/* == SCROLL BAR == */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #050718;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #25518c;
}

/* == || SCROLL BAR == */
/* == GENERAL == */
.container {
  width: 100%;
  max-width: 1400px !important;
  margin: auto;
  padding: 0px 15px;
}

/* == || GENERAL == */

/* == Header == */
header {
  height: fit-content;
  width: 100%;
}

.header-container {
  padding: 10px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.header-contacts {
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.navbar{
  position: sticky;
  top: 0;
  background-color: #ffffff;
  z-index: 999;
}

.header-contacts .contact {
  padding: 10px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 20px;
}

.header-contacts .contact .pad {
  padding: 0px 5px;
}

.header-contacts .contact p:nth-child(1) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #25518c;
}

.header-contacts .contact p:nth-child(2) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #25518c;
}

nav {
  width: 100%;
  border-top: 1px solid rgba(28, 27, 31, 0.2);
}

.nav-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-container .logo {
  display: none;
}

.links {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.links a {
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  padding: 10px 10px;
}

.links .active-link {
  color: #de4404;
  border-bottom: 4px solid #ffffff;
}

.links a:hover {
  color: #de4404;
  border-bottom: 4px solid #de4404;
}
.dropdown {
  position: absolute;
  width: fit-content;
  height: fit-content;
  top: 150px;
  /* K.A.I.C.T. - Light Blue */
  background: #eff1fb;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 16px;
  gap: 15px;
  position: absolute;
  left: 48%;
  animation-name: nav-dropdwon;
  animation-duration: 200ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}
.dropdown-link {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #1c1b1f;
}
.dropdown-link:hover {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #de4404;
}

.nav-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #25518c;
}

.nav-logo img {
  margin: 0px 10px 0px;
}

.menu-bar {
  padding: 5px;
}

.menu-btn {
  font-size: 28px;
  padding: 10px 20px;
}

/* == || Header == */
/* == BANNER == */
section.banner {
  height: fit-content;
  background-image: url(../public/images/home/hero.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.banner-mask {
  background-color: #0000009c;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner-text {
  width: 100%;
  max-width: 1200px;
  margin: auto;
}

.banner-text h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  text-align: center;
  color: #fcfcfc;
  font-size: 42px !important;
  line-height: 150% !important;
}

.banner-text p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  text-align: center;
  color: #fcfcfc;
  margin: 20px 0px;
}

.banner-text button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  background: #fcfcfc;
  border-radius: 5px;
  margin: auto;
}

/* == || BANNER == */
/* == UPCOMING EVENTS == */
.upcoming-events {
  background-color: #eff1fb;
  padding: 60px 0px;
}

.upcoming-container {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.upcoming-container .up-left,
.upcoming-container .up-right {
  padding: 25px 0px;
}

.up-left .top {
  padding: 25px 0px;
}

.up-left .top h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  color: #1c1b1f;
}

.up-left .bottom {
  width: 100%;
  max-width: 1000px;
  min-width: 320px;
  padding: 25px 5px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.bottom .up-card {
  background: #fcfcfc;
  box-shadow: 10px 32px 72px rgba(51, 51, 51, 0.04);
  border-radius: 5px;
  height: 100%;
  max-height: 422px;
  min-height: 368px;
  width: fit-content;
  max-width: 384px;
  min-width: 260px;
  padding: 20px;
  position: relative;
  margin: 10px 5px;
}

.bottom .up-card img {
  width: 100%;
}

.bottom .up-card .floating-card {
  position: absolute;
  top: 40px;
  right: 40px;
  height: fit-content;
  width: 80%;
  padding: 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.floating-card .hold {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.floating-card span.price {
  height: 40px;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #25518c;
}

.floating-card span.share,
.floating-card span.love {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin: 0px 8px;
  color: #1c1b1f;
}

.floating-card span i {
  font-size: 20px;
  color: #1c1b1f;
}

.up-card-info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 0px;
}

.up-card-info .left {
  padding: 10px 0px 0px;
}

.up-card-info .left span:nth-child(1) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #1c1b1f;
}

.up-card-info .left span:nth-child(3) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: #1c1b1f;
}

.up-card-info .right {
  padding: 0px 0px 0px 20px;
}

.up-card-info .right a {
  text-decoration: none;
  cursor: pointer;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #25518c;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.up-card-info .right span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1c1b1f;
  white-space: nowrap;
  display: flex;
  gap: 3px;
  align-items: center;
}

.bottom-more {
  padding: 20px 0px;
}

.bottom-more button {
  padding: 16px 24px;
  height: 50px;
  background: #fcfcfc;
  border-radius: 5px;
  outline: none;
  border: 0px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #25518c;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.up-right {
  max-width: 384px;
  width: 100%;
  min-width: 280px;
}

.up-right .top {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 25px 0px;
}

.up-right .top span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  color: #1c1b1f;
}

.up-right .top a {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #25518c;
  padding: 18px 10px 18px;
}

.up-right .news {
  padding: 10px 0px;
}

.up-right .news .top {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.up-right .news .top .left {
  width: 149px;
  height: 34px;
  background: #25518c;
  border-radius: 6px 0px 0px 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.up-right .news .top .left span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 120%;
  /* identical to box height, or 16px */
  text-align: center;
  text-transform: uppercase;
  color: #fcfcfc;
}

.up-right .news .top .right {
  width: 149px;
  height: 34px;
  background: #fcfcfc;
  border-radius: 0px 6px 6px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.up-right .news .top .right span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 120%;
  /* identical to box height, or 16px */
  text-align: center;
  text-transform: uppercase;
  color: #1c1b1f;
}

.up-right .news .bottom h4 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #1c1b1f;
  margin: 0px 0px 5px;
}

.up-right .news .bottom p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1c1b1f;
  margin: 10px 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

/* == || UPCOMING EVENTS == */
/* == OFFERS == */
.offers {
  padding: 60px 0px;
}

.offer-head {
  padding: 40px 0px 20px;
}

.offer-head h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  text-align: center;
  color: #1c1b1f;
}

.offer-content {
  padding: 10px 0px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.offer-card {
  width: 280px;
  z-index: 0;
  position: relative;
  margin: 30px 0px;
}

.offer-card img {
  position: relative;
  z-index: 0;
  height: 300px;
  border-radius: 8px;
  width: 100%;
  object-fit: cover;
}

.offer-info {
  width: 250px;
  height: 333px;
  background: #fcfcfc;
  border: 1px solid #e0e2f1;
  border-radius: 5px;
  position: relative;
  z-index: 5;
  margin: -60px auto 0px;
  text-align: center;
  padding: 32px 0px 0px;
}

.offer-info span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  color: #1c1b1f;
  margin: 10px auto;
}
.offer-info .icon {
  max-width: 40px;
  max-height: 40px;
  width: 100%;
  object-fit: contain;
  align-self: center;
  justify-self: center;
}

.offer-info h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #1c1b1f;
  margin: 10px auto;
}

.offer-info p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #1c1b1f;
  margin: 10px auto;
  max-width: 200px;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  overflow: hidden;

  text-overflow: ellipsis;
}

.offer-info button {
  background: #25518c;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: 0px;
  position: absolute;
  bottom: -25px;
  left: 100px;
}

/* == || OFFERS == */
/* == Projects == */
.projects {
  padding: 60px 0px;
  background: #eff1fb;
  border-radius: 5px;
}

.projects-head {
  padding: 40px 0px 20px;
}

.projects-head h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  text-align: center;
  color: #1c1b1f;
}

.projects-content {
  padding: 20px 15px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.project {
  width: 100%;
  max-width: 584px;
  margin: 10px;
  border: 1px solid #e0e2f1;
  border-radius: 5px;
}

.project img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.project .project-info {
  padding: 10px 10px;
}

.project-info h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 36px;
  color: #1c1b1f;
  margin: 10px 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.project-info p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1c1b1f;
  margin: 10px 0px;
}

.project-info button {
  outline: none;
  border: 0;
  width: 95%;
  height: 50px;
  background: #25518c;
  border-radius: 5px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #fcfcfc;
  margin: 10px auto;
}

/* == || Projects == */
/* == Think == */
.think-tanks {
  padding: 60px 0px;
}

.think-head {
  padding: 40px 0px 20px;
}

.think-head h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  text-align: center;
  color: #1c1b1f;
}

.think-tank-card:nth-child(even) {
  flex-direction: row-reverse;
}

.think-tank-info h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 36px;
  color: #1c1b1f;
  margin: 0px 0px;
}

.think-tank-info p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1c1b1f;
  margin: 10px 0px;
  line-height: 1.2em; /* Adjust the line height as needed */
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;

}

.think-tank-info button {
  outline: none;
  border: 0;
  width: 95%;
  height: 50px;
  background: #25518c;
  border-radius: 5px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #fcfcfc;
  margin: 10px auto;
}

/* == || Think == */
/* == Contact == */
section.contact {
  padding: 60px 0px;
  background-color: #eff1fb;
}

.contact-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.contact-details,
.contact-form {
  padding: 10px;
  width: 100%;
  max-width: 584px;
  min-width: 280px;
}

.contact-details .title {
  padding: 0px 0px 16px;
}

.contact-details .title h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 49px;
  color: #1c1b1f;
  margin: 10px 0px;
}

.contact-details .title p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #de4404;
  margin: 10px 0px;
}

.contact-details .details {
  padding: 16px 0px;
}

.details .detail {
  height: 48px;
  width: fit-content;
  padding: 12px 24px;
  background: #eff1fb;
  border: 1px solid #e0e2f1;
  border-radius: 5px;
  margin: 16px 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.details .detail span {
  margin: 0px 16px 0px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1c1b1f;
}

.contact-details iframe {
  margin: 30px 0px 10px;
  width: 100%;
  max-width: 560px;
  min-width: 260px;
  height: 100%;
  max-height: 287px;
  aspect-ratio: 560/287;
}

.contact-form form .names {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

form .control-group {
  width: 100%;
  max-width: 564px;
  min-width: 260px;
  padding: 10px;
}

form .control-group label {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1c1b1f;
  padding: 0px 0px 10px;
}

form .control-group input {
  outline: none;
  width: 100%;
  height: 50px;
  border: 1px solid #e0e2f1;
  border-radius: 5px;
  background-color: #eff1fb;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1c1b1f;
  padding: 13px 20px;
}

form .control-group input::placeholder,
form .control-group textarea::placeholder {
  color: #d9d9d9;
}

form textarea {
  height: 150px;
  width: 100%;
  border: 1px solid #e0e2f1;
  border-radius: 5px;
  background-color: #eff1fb;
  max-width: 564px;
  min-width: 260px;
  padding: 13px 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1c1b1f;
}

.control-group.checkbox {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.control-group.checkbox label {
  font-size: 12px;
  line-height: 18px;
  margin: 0px 0px 0px 10px;
}

#checkboxed {
  width: 18px !important;
  height: 18px !important;
  border: 2px solid #e0e2f1 !important;
  border-radius: 2px !important;
  background-color: #eff1fb !important;
}

.control-group button {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #fcfcfc;
  height: 50px;
  width: 100%;
  background: #25518c;
  border-radius: 5px;
}

/* == || Contact == */
/* == Footer == */
footer {
  height: fit-content;
  padding: 80px 0px 20px;
  background: #25518c;
}

/* .footer-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
} */

.col {
  width: 100%;
  margin: 10px;
}

.col.one {
  max-width: 600px;
  min-width: 280px;
}

.col.one h4 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 610;
  font-size: 20px;
  line-height: 36px;
  color: #fcfcfc;
  margin: 10px 0px;
}

.col.one p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #fcfcfc;
  margin: 10px 0px 13px;
}

.col.two,
.col.three {
  max-width: 300px;
  min-width: 280px;
  margin: 10px;
}

.col h4 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  color: #fcfcfc;
  margin: 10px 0px;
}

.col ul {
  margin: 30px 0px 0px;
}

.col ul li {
  padding: 5px 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.col ul li a {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #fcfcfc;
}

.bottom-footer p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #fcfcfc;
  margin: 10px;
}


/* == || Footer == */

/* == ABOUT == */
section.about,
section.about .banner-mask {
  min-height: 400px;
}

.about-banner-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.banner-text {
  width: 100%;
  max-width: 1200px;
  margin: auto;
}

section.about .banner-text h1,
section.about .banner-text p {
  text-align: left !important;
}

.why {
  background: #eff1fb;
  padding: 60px 0px;
}

.why-container {
  align-items: center;
  justify-content: space-evenly;
}

.why-container .left {
  width: 100%;
}

.why-container .left p:nth-child(1) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #1c1b1f;
  text-align: justify;
}

.why-container .left h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 60px;
  color: #25518c;
}

.why-container .left p:nth-child(3) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1c1b1f;
  text-align: justify;

}

.why-checked {
  width: 100%;
}

.check-ctrl {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.check-ctrl .check {
  width: 100%;
  max-width: 300px;
  min-width: 280px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 0px;
}

.check-ctrl .check span {
  padding: 0px 0px 0px 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1c1b1f;
}

.why-container .right {
  padding: 0px 0px;
}
 .desc span{
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  /* or 24px */
  color: #FCFCFC;
}
.tab{
  font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 150%;
}

.team-container .card{
  width: 380px;
height: 110px;
/* K.A.I.C.T. White */
background: #FCFCFC;

}
.team-container .card h5{
  font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 150%;
/* identical to box height, or 30px */

text-align: center;

color: #25518C;
}

.why-container .right img {
  width: 100%;
}
.team-container .card p{
  font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 150%;
/* identical to box height, or 30px */

text-align: center;

/* K.A.I.C.T. Black */

color: #1C1B1F;
}
.horizontal-hr{
  width: 50px;
height: 0px;

/* K.A.I.C.T. Blue */

border: 2px solid #25518C;
}
.vertical-hr{
  width: 0px;
  height: 50px;
  border: 2px solid #25518C;
}

.missions {
  padding: 80px 0px;
  background-color: #ffffff;
}

.missions-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.missions-container .mission {
  max-width: 390px;
  width: 100%;
  min-width: 280px;
  min-height: 232px;
  height: fit-content;
  padding: 20px;
  margin: 10px 0px;
}

.missions-container .mission h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  margin: 0px 0px 10px;
  text-align: left;
}

.missions-container .mission.one h2,
.missions-container .mission.two h2 {
  color: #fcfcfc;
}

.missions-container .mission.three h2 {
  color: #1c1b1f !important;
}

.missions-container .mission p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #fcfcfc;
  margin: 10px 0px;
  text-align: left;
}

.mission li {
  padding: 5px 0px;
}

.mission li span:nth-child(1) {
  width: 40px !important;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  color: #1c1b1f;
}

.mission li span:nth-child(2) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1c1b1f;
  padding: 0px 0px 0px 18px;
}

.mission.one {
  background: #25518c;
}

.mission.two {
  background: #de4404;
}

.mission.three {
  background: #eff1fb;
}

section.choices {
  height: fit-content;
  background-image: url(../public/images/home/hero.png);
  background-repeat: no-repeat;
  background-size: cover;
  
}

.choices-mask {
  background: linear-gradient(
    0deg,
    rgba(28, 27, 31, 0.75),
    rgba(28, 27, 31, 0.75)
  );
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 0px 40px;
}

.choice-head {
  padding: 60px 0px;
}
.choice-head p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #fcfcfc;
}

.choice-content {
  padding: 0px 0px 20px;
}

.choice-content .row {
  padding: 0px 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}

.choice-head h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  text-align: center;
  color: #fcfcfc;
}

.column.choice {
  width: 100%;
  height: 270px;
  border: 3px solid #e0e2f1;
  padding: 6px;
  margin: 10px 0px;
  overflow-y: scroll;
}

.choice-content .column {
  width: 100%;
  min-width: 280px;
  max-width: 390px;
  height: 270px;
  overflow-y: scroll;
}

.column.choice h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 36px;
  text-align: center;
  color: #fcfcfc;
}

.column.choice p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #fcfcfc;
}

section.team {
  background: #eff1fb;
  padding: 20px 0px;
}

.team-head {
  padding: 20px 0px;
}

.team-head p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #1c1b1f;
}

.team-head h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  text-align: center;
  color: #1c1b1f;
}

.team-content {
  padding: 20px 10px;
}

.team-content .member {
  height: fit-content;
  width: 80%;
  margin: 10px 0px;
}

.team-content .member img {
  width: 100%;
  height: 180px;
  object-fit: contain;
  background-color: #9a9a9a;
}

.team-content .member-info {
  width: 100%;
  padding: 20px;
  background-color: #ffffff;
}

.member-info span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #1c1b1f;
}

.member-info h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #25518c;
  margin: 0px 0px 10px;
}

.member-info p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1c1b1f;
  margin: 10px 0px;
}

.member-info a {
  text-decoration: none;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #25518c;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 10px 0px;
}
.member-info a svg {
  margin: 0px 0px 0px 20px;
}
section.contact.about,
section.contact.about .detail,
section.contact.about .contact-form input,
section.contact.about .contact-form textarea {
  background-color: #ffffff !important;
}

/* == || ABOUT == */

/* == NEWS == */
section.news {
  padding: 60px 0px;
}

section.news .news-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

section.news .news-container .left {
  width: 100%;
  max-width: 700px;
  min-width: 280px;
}

section.news .news-container .head {
  padding: 20px 0px;
  border-bottom: 1px solid #9a9a9a;
  width: 100%;
  max-width: 700px;
  min-width: 280px;
}

section.news .news-container .head h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 49px;
  color: #1c1b1f;
}

section.news .news-content {
  height: fit-content;
  width: 100%;
  max-width: 700px;
  min-width: 280px;
  padding: 20px 0px;
}

section.news .news-container .right {
  width: 100%;
  max-width: 500px;
  min-width: 280px;
}

.feed {
  width: 100%;
  max-width: 700px;
  min-width: 280px;
  position: relative;
}

.feed img {
  width: 100%;
  position: relative;
  z-index: 0;
  vertical-align: middle;
  height: 350px;
  object-fit: cover;
}

.feed .text {
  height: fit-content;
  width: fit-content;
  position: absolute;
  bottom: 40px;
  left: 40px;
}

.feed .feed-mask {
  height: 100%;
  width: 100%;
  max-width: 700px;
  min-width: 280px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background: linear-gradient(
    0deg,
    rgba(27, 39, 70, 0.65),
    rgba(27, 39, 70, 0.65)
  );
}

.feed .text h3 {
  width: 100%;
  max-width: 540px;
  min-width: 260px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #fcfcfc;
  margin: 0px 0px 11px;
}

.feed .text a {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #fcfcfc;
  margin: 11px 0px 0px;
}
.dots {
  width: fit-content;
  margin: auto !important;
  padding: 20px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dots .dot {
  background: #9a9a9a;
  height: 6px;
  width: 6px;
  border-radius: 3px;
  margin: 0px 3.5px;
}
.dots .dot.active {
  background-color: #de4404;
}

.news-card-slides {
  width: 100%;
  padding: 36px 0px;
}

.news-cards {
  width: 100%;
}

.news-cards .card {
  height: 417px;
  width: 100%;
  margin: 14px 0px;
  background: #fcfcfc;
  box-shadow: 0px 3px 20px rgba(2, 2, 2, 0.1);
}

.news-cards .card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.news-card-info {
  padding: 20px;
}

.news-card-info h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #1c1b1f;
}

.news-card-info p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #1c1b1f;
  margin: 10px 0px 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.news-card-info a {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  width: fit-content;
  padding: 4px 0px;
  line-height: 20px;
  text-align: center;
  color: #25518c;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.news-card-info a svg {
  margin: 0px 6px 0px;
}

.news-container .pagination {
  width: fit-content;
  margin: auto;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.news-container .pagination .page {
  width: 40px;
  height: 40px;
  background: #eff1fb;
  margin: 0px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.news-container .pagination .page span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #1c1b1f;
}

.news-container .pagination .page.more {
  width: 82px;
}

.news-list {
  width: 100%;
  max-width: 500px;
  min-width: 280px;
  background: #fcfcfc;
  box-shadow: 0px 3px 20px rgba(2, 2, 2, 0.1);
  padding: 40px;
  margin: 0px 0px 40px;
}

.news.top {
  background-color: #fcfcfc;
}

.head-new {
  padding: 0px 0px 15px;
}

.head-new h4 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #1c1b1f;
}

.news-list ul li {
  padding: 0px 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.news-list ul li a svg {
  margin-right: 10px;
}

.news-list ul li a {
  padding: 5px 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #1c1b1f;
}

.news-list .news {
  padding: 10px 0px;
}

.news-list .news .top {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.news-list .news .top .left-tag {
  width: fit-content;
  padding: 0px 14px;
  height: 34px;
  background: #25518c;
  border-radius: 6px 0px 0px 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.news-list .news .top .left-tag span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 120%;
  /* identical to box height, or 16px */
  text-align: center;
  text-transform: uppercase;
  color: #fcfcfc;
}

.news-list .news .top .right-tag {
  width: fit-content;
  padding: 0px 14px;
  height: 34px;
  background: #fcfcfc;
  border-radius: 0px 6px 6px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.news-list .news .top .right-tag span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 120%;
  /* identical to box height, or 16px */
  text-align: center;
  text-transform: uppercase;
  color: #1c1b1f;
}

.news-list .news .bottom {
  padding: 20px 0px 0px;
}

.news-list .news .bottom h4 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #1c1b1f;
  margin: 0px 0px 5px;
}

.news-list .news .bottom p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1c1b1f;
  margin: 10px 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.side-socials {
  background: #fcfcfc;
  box-shadow: 0px 3px 20px rgba(2, 2, 2, 0.1);
  margin: 0px 0px 20px;
  width: 100%;
}

.side-socials img {
  width: 100%;
}

.side-info {
  padding: 40px;
}

.side-info .side-contact {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 0px;
}

.side-info .side-contact .icon {
  width: 50px;
  height: 50px;
  left: 880px;
  top: 1997px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  margin: 0px 20px 0px 0px;
}

.side-info .side-contact .icon.one {
  background: #25518c;
}

.side-info .side-contact .icon.two {
  background: #de4404;
}

.side-contact .text h5 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #1c1b1f;
  margin: 0px 0px 1px;
}

.side-contact .text p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #1c1b1f;
}

.social-side-icons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.social-side-icons a {
  margin: 0px 6px 0px;
  padding: 4px;
}

.single-news {
  width: 100%;
  max-width: 700px;
  min-width: 280px;
}

.news-thumbnail {
  width: 100%;
  max-width: 700px;
  min-width: 280px;
}

.news-thumbnail img {
  width: 100%;
  max-width: 700px;
  min-width: 280px;
}

.news-thumbnail-info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 10px 0px;
}

.sub-items {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 20px 10px 0px;
}

.sub-items svg {
  margin: 0px 10px 0px 0px;
}

.single-news-content {
  padding: 10px 0px;
}

.single-news-content h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #1c1b1f;
  margin: 8px 0px;
}

.single-news-content p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #1c1b1f;
  margin: 14px 0px;
}

.news-caption {
  width: 100%;
  max-width: 700px;
  min-width: 280px;
  border: 1px solid #9a9a9a;
  margin: 10px 0px;
}

.news-caption img {
  width: 100%;
  max-width: 700px;
  min-width: 280px;
}

.news-caption-info {
  padding: 15px 20px;
}

.news-caption-info p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #1c1b1f;
}

.posts {
  padding: 20px 0px;
  border-top: 1px solid #9a9a9a;
}

.posts-slider {
  padding: 20px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.posts-slider .arrow button {
  background-color: #00000000;
  outline: none;
  border: 0;
  padding: 10px;
}

.post-slide .slide-top {
  width: 100%;
  max-width: 700px;
  min-width: 280px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0px;
}

.post-slide .slide-top span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #1c1b1f;
}

.post-slide .slide-content {
  width: 100%;
  max-width: 700px;
  min-width: 280px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0px;
}

.slide-content .post a {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #25518c;
}

.comments-head {
  border-bottom: 1px solid #9a9a9a;
  padding: 20px 0px;
}

.comments-head h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 49px;
  color: #1c1b1f;
}

.comment-form {
  padding: 10px 0px;
}

.comment-form form .control-group {
  width: 100%;
  max-width: 700px;
  min-width: 260px;
  padding: 10px;
}

.comment-form form .control-group .comment-input,
.comment-form form .control-group textarea {
  width: 100%;
  max-width: 700px;
  min-width: 260px;
  background-color: #ffffff;
}

.comments-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.comments-head span:nth-child(1) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 49px;
  color: #1c1b1f;
}

.comments-head span:nth-child(2) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
  color: #1c1b1f;
}

.persons-comments {
  padding: 20px 0px;
}

.comment {
  width: 100%;
  max-width: 700px;
  min-width: 260px;
  padding: 20px 0px;
}

.comment .top-comment {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0px;
}

.comment .top-comment span:nth-child(1) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #1c1b1f;
}

.comment .top-comment span:nth-child(2) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #9a9a9a;
}

.comment-msg {
  padding: 5px 0px;
}

.comment-msg p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #1c1b1f;
}

.commented-reply,
.reply-msgs {
  padding: 0px 0px 0px 20px;
}

/* == || NEWS == */

/* == BLACK == */
/* section.black-banner {
  background-image: url('img/black\ banner.png') !important;
} */

.news-container.black-history {
  justify-content: space-around !important;
}

.upcoming-black {
  background-color: #eff1fb;
  padding: 60px 0px 80px;
}

.upcoming-black .head {
  padding: 20px 0px 20px;
}

.upcoming-black .head h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 37px;
  /* identical to box height */

  text-align: center;

  /* K.A.I.C.T. Blue */

  color: #25518c;
}

.at-glance {
  width: 100%;
  max-width: 500px;
  min-width: 280px;
  padding: 40px;
  background: #fcfcfc;
  box-shadow: 0px 3px 20px rgba(2, 2, 2, 0.1);
  border-radius: 2px;
  margin: 0px 0px 80px;
}

.glance-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;

  color: #1c1b1f;
}

.glance-content {
  padding: 20px 0px 0px;
}

.glance-content ul,
.glance-content ul li {
  width: 100%;
}

.glance-content ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #9a9a9a;
  padding: 10px 0px;
  margin-bottom: 14px;
}

.glance-content ul li span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.glance-content ul li span svg {
  margin-right: 10px;
}

.glance-content ul li span:nth-child(1) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #1c1b1f;
}

.glance-content ul li span:nth-child(2) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
  color: #9a9a9a;
}

.glance-content button {
  width: 100%;
  height: 40px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  text-align: center;
}

.glance-content .btn-attend {
  margin: 30px 0px 10px;
  background-color: #25518c;
  border: 1px solid #25518c;
  color: #fcfcfc;
}

.glance-content .btn-download {
  margin: 10px 0px 0px;
  border: 1px solid #25518c;
  color: #25518c;
  background-color: #ffffff;
}

.news-thumbnail.black {
  padding: 0px 0px 20px;
  width: 100%;
  max-width: 700px;
  min-width: 280px;
}

.black-head {
  padding: 20px 0px;
}

.black-head h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 37px;
  color: #25518c;
}

.black-text p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1c1b1f;
}

.black-schedule {
  height: fit-content;
  width: 100%;
  max-width: 700px;
  min-width: 280px;
  padding: 10px 0px;
  border-bottom: 2px solid #25518c;
  margin-bottom: 20px 0px 0px;
}

.black-schedule .top {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 0px 10px;
}

.event-blk-speaker {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.speech-time {
  padding: 0px 20px 0px 0px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #1c1b1f;
}

.event-blk-speaker img {
  margin-right: 20px;
  border-radius: 50%;
}

.speaker-info h5 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #1c1b1f;
}

.speaker-info p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1c1b1f;
}

.black-schedule .bottom {
  padding: 10px 0px 0px;
}

.black-schedule .bottom h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #1c1b1f;
  margin: 5px 0px;
}

.black-schedule .bottom p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1c1b1f;
  margin: 5px 0px;
}

form.black .names {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

form.black .control-group {
  width: 100%;
  max-width: 700px;
  min-width: 260px;
  padding: 10px;
}

form.black .control-group label {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1c1b1f;
  padding: 0px 0px 10px;
}

form.black .control-group input {
  outline: none;
  width: 100%;
  height: 50px;
  border: 1px solid #e0e2f1;
  border-radius: 5px;
  background-color: #ffffff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1c1b1f;
  padding: 13px 20px;
}

form.black .control-group.name,
form.black .control-group.name input {
  max-width: 330px;
  width: 100%;
}

form.black .control-group input::placeholder,
form.black .control-group textarea::placeholder {
  color: #d9d9d9;
}

form.black textarea {
  height: 150px;
  width: 100%;
  border: 1px solid #e0e2f1;
  border-radius: 5px;
  background-color: #eff1fb;
  max-width: 700px;
  min-width: 260px;
  padding: 13px 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1c1b1f;
}

form.black .control-group button {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #fcfcfc;
  height: 50px;
  width: 100%;
  background: #25518c;
  border-radius: 5px;
}

.black-events {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  background-color: #eff1fb;
  padding: 20px 0px 0px;
}

.black-card {
  background: #fcfcfc;
  /* shadow */
  box-shadow: 10px 32px 72px rgba(51, 51, 51, 0.04);
  border-radius: 5px;
  height: 100%;
  max-height: 382px;
  min-height: 278px;
  width: fit-content;
  max-width: 384px;
  min-width: 260px;
  padding: 20px;
  position: relative;
  margin: 10px 5px;
}

.black-card img {
  width: 100%;
}

.black-card .floating-card {
  position: absolute;
  top: 40px;
  right: 40px;
  height: fit-content;
  width: 80%;
  padding: 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.black-card .floating-card .hold {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.black-card .floating-card span.price {
  height: 40px;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #25518c;
}

.black-card .floating-card span.share,
.black-card .floating-card span.love {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin: 0px 8px;
  color: #1c1b1f;
}

.black-card .floating-card span i {
  font-size: 20px;
  color: #1c1b1f;
}

.black-card-info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 0px;
}

.black-card-info .left {
  padding: 10px 0px 0px;
}

.black-card-info .left span:nth-child(1) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #1c1b1f;
}

.black-card-info .left span:nth-child(3) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: #1c1b1f;
}

.black-card-info .right {
  padding: 0px 0px 0px 20px;
}

.black-card-info .right a {
  text-decoration: none;
  cursor: pointer;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #25518c;
}

.black-card-info .right span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1c1b1f;
}

/* == BLACK == */

/* == TEACHING AND TRAINING == */
/* section.teaching-banner {
  background-image: url('img/teaching\ and\ training\ banner.png') !important;
} */


.train-course .left {
  position: relative;
  z-index: -1;
}

.train-course .right {
  position: relative;
}

.train-course .left img {
  width: 100%;
  max-width: 700px;
  min-width: 280px;
  position: relative;
}


.train-course-card {
  height: fit-content;
  background: #efecec;
  box-shadow: 0px 3px 20px rgba(2, 2, 2, 0.1);
  padding: 60px 40px;
  position: relative;
  z-index: 1;
}

.train-course-card.right-card {
  margin-left: -80px;
}

.train-course-card.left-card {
  margin-right: -80px;
  z-index: 6;
}

.train-course-card h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 37px;
  color: #25518c;
  margin: 0px 0px 10px;
}

.train-course-card p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #1c1b1f;
  margin: 10px 0px;
}

.train-course-card a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 272px;
  height: 40px;
  background: #25518c;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #fcfcfc;
  margin: 10px 0px 0px;
}

.train-course-card button svg {
  margin-left: 16px;
}

/* == || TEACHING AND TRAINING == */

/* == PROGRAM == */
/* section.teaching-banner {
  background-image: url('img/masters.png') !important;
} */

section.program-info.top {
  padding: 60px 0px 0px;
}

section.program-info.bottom {
  padding: 0px 0px 60px;
}

.prog-btn {
  padding: 20px 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.prog-btn button {
  width: fit-content;
  padding: 0px 20px;
  margin-right: 20px;
  outline: none;
  height: 40px;
  border: 3px solid #25518c;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #25518c;
  background-color: #ffffff;
}

.prog-head {
  padding: 20px 0px;
}

.prog-head h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 37px;
  color: #25518c;
}
.prog-head p{
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1c1b1f;
  margin: 20px 0px;
  text-align: justify;
}

.prog-txt p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1c1b1f;
  margin: 20px 0px;
  text-align: justify;
}

.prog-txt.left,
.prog-txt.right {
  width: 100%;
}

.prog-txt p.elective,
.prog-txt ul li {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1c1b1f;
  margin: 4px 0px;
  text-align: justify;
}

button.btn-brochure {
  width: 100%;
  height: 40px;
  background: #25518c;
  outline: none;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #fcfcfc;
  margin: 20px 0px;
  text-align: center;
}

.prog-txt ul {
  padding: 0px 0px 0px 20px;
}

.prog-txt ul li {
  list-style: disc !important;
}

.prog-txt table {
  margin: 0px 0px 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  width: 100%;
}

.prog-txt table tbody tr:nth-child(1) {
  background: #162f52;
  padding: 8px 20px;
  width: 100%
  
}

.prog-txt table tbody tr:nth-child(1) td {
  background: #162f52;
  padding: 8px 20px;
  color: #ffffff;
  font-weight: 600;
  width: 100%
}

.prog-txt table tbody tr:nth-child(2) td,
.prog-txt table tbody tr:nth-child(3) td,
.prog-txt table tbody tr:nth-child(4) td,
.prog-txt table tbody tr:nth-child(5) td,
.prog-txt table tbody tr:nth-child(6) td,
.prog-txt table tbody tr:nth-child(7) td {
  padding: 8px 30px;
}

.prog-txt table tbody tr:nth-child(2) {
  background: #eff1fb;
}

.prog-txt table tbody tr:nth-child(3) {
  background: #ffffff;
}

.prog-txt table tbody tr:nth-child(4) {
  background: #eff1fb;
}

.prog-txt table tbody tr:nth-child(5) {
  background: #ffffff;
}

.prog-required ol {
  list-style: upper-roman !important;
  padding: 20px 0px 20px 20px;
}

.prog-carding {
  margin: 20px 0px;
  padding: 80px 0px;
  background: #25518c;
}

.prog-carding-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.prog-card {
  min-height: 176px;
  width: 100%;
  max-width: 400px;
  min-width: 280px;
  border-radius: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
}

.card-cont {
  text-align: center;
}

.prog-required {
  padding: 20px 0px;
}

.prog-card span:nth-child(1) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 49px;
  text-align: center;
  color: #1c1b1f;
}

.prog-card span:nth-child(3) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  text-align: center;
  color: #1c1b1f;
}

.btn-curri {
  width: 100%;
  padding: 10px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.btn-curri button {
  height: 40px;
  width: 280px;
  border-radius: 0px;
  background: #25518c;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #fcfcfc;
}

.prog-info-container.next-to {
  padding: 40px 0px;
}

h2.right-text,
p.right-text {
  text-align: right;
}

table,
th,
td {
  border: none !important;
  border-collapse: collapse;
}

table.bottom {
  font-size: 14px !important;
}

table.bottom tbody tr:nth-child(2) {
  background: #9a9a9a;
  color: #ffffff;
}

table.bottom tbody tr:nth-child(3) {
  background: #ffffff;
}

table.bottom tbody tr:nth-child(4) {
  background: #eff1fb;
}

table.bottom tbody tr:nth-child(5) {
  background: #ffffff;
}

table.bottom tbody tr:nth-child(6) {
  background: #eff1fb;
}

table.bottom tbody tr:nth-child(7) {
  background: #ffffff;
}

section.prog-application {
  padding: 80px 0px;
  /* background-image: url(../public/images/prog\ apply.png); */
  background-size: cover;
  background-repeat: no-repeat;
}

.application-form {
  padding: 40px;
  width: 100%;
  max-width: 530px;
  min-height: 511px;
  background: rgba(37, 81, 140, 0.9);
  box-shadow: 0px 3px 20px rgba(2, 2, 2, 0.1);
}

form.apply h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 37px;
  color: #fcfcfc;
  margin-bottom: 30px;
}

form.apply .control-group {
  width: 100%;
  max-width: 450px;
  min-width: 260px;
  padding: 10px 0px;
}

form.apply .control-group label {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #fcfcfc;
  margin-bottom: 10px;
}

form.apply .control-group select,
form.apply .control-group option {
  width: 100%;
  max-width: 450px;
  height: 50px;
  border: 1px solid #e0e2f1;
  padding: 12px 30px;
  background: rgba(37, 81, 140, 0.9);
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #fcfcfc;
}

form.apply .control-group input::placeholder,
form.apply .control-group textarea::placeholder {
  color: #d9d9d9;
}

form.apply .control-group.checkbox {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  padding: 10px 10px 10px 35px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

form.apply .control-group.checkbox label {
  font-size: 12px;
  line-height: 18px;
  margin: 0px 0px 0px 6px;
  color: #ffffff !important;
}

/* Hide the browser's default checkbox */
.control-group.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.control-group.checkbox .checkmark {
  position: absolute;
  top: 15px;
  left: 10px;
  height: 18px;
  width: 18px;
  background: rgba(37, 81, 140, 0.9);
  border: 2px solid #ffffff;
}

/* On mouse-over, add a grey background color */
.control-group.checkbox .container-check:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.control-group.checkbox .container-check input:checked ~ .checkmark {
  background: rgba(37, 81, 140, 0.9);
}

/* Create the checkmark/indicator (hidden when not checked) */
.control-group.checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.control-group.checkbox .container-check input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.control-group.checkbox .container-check .checkmark:after {
  left: 4px;
  top: 1px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

form.apply .control-group button {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #25518c;
  height: 50px;
  width: 100%;
  background: #ffffff;
  border-radius: 1px;
  margin-top: 30px;
}

/* == || PROGRAM == */

/* == LIBRARY == */
/* section.library-banner {
  background-image: url('img/library\ banner.png') !important;
} */

section.lib-search {
  background: #eff1fb;
  padding: 60px 0px;
}

form.search {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px 0px;
}

.search-input {
  width: 100%;
  max-width: 920px;
  min-width: 280px;
  padding: 0px;
}

.form-control {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 0px;
}

input.search-input-data {
  height: 50px;
  width: 100%;
  max-width: 920px;
  min-width: 280px;
  padding: 18px 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  background-color: #eff1fb;
  border: 1px solid #9a9a9a;
}

input.search-input-data:placeholder {
  color: #9a9a9a;
}

.radio {
  padding: 0px 20px 0px 0px;
}

.radio label {
  padding: 0px 20px;
}

.search-submit {
  padding: 10px 0px;
}

.search-submit button {
  width: 200px;
  height: 50px;
  border: 0;
  outline: none;
  background: #25518c;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  color: #fcfcfc;
}

.return-data {
  padding: 80px 0px;
}

.return-data-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
}

.return-data-container .left {
  width: 100%;
  max-width: 420px;
}

.return-data-card {
  background: #fcfcfc;
  box-shadow: 0px 3px 20px rgba(2, 2, 2, 0.1);
  border-radius: 2px;
  padding: 40px;
  margin: 0px 0px 40px;
}

.return-data-card h2 {
  margin: 0px 0px 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;

  /* K.A.I.C.T. Black */
  color: #1c1b1f;
}

.return-data-card li {
  padding: 5px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.return-data-card li a {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #25518c;
}

.return-data-card li span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #9a9a9a;
}
.input-year input,
.return-data-card li {
  margin-right: 10px;
}

.return-data-container .right {
  width: 100%;
  max-width: 800px;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.content-card {
  min-height: 407px;
  width: 100%;
  max-width: 380px;
  min-width: 280px;
  margin: 0px 0px 20px;
}

.content-card img {
  width: 100%;
  height: 300px;
  vertical-align: middle;
}

.content-info {
  background: #eff1fb;
  padding: 20px;
  position: relative;
  height: 280px;
}

.content-info .year {
  position: absolute;
  width: fit-content;
  height: 44px;
  right: 20px;
  top: -22px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #25518c;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #fcfcfc;
}

.content-info .top {
  padding: 0px 0px 10px;
}

.content-info .bottom {
  padding: 10px 0px 0px;
}

.content-info .top span.no-margin {
  margin: 0px !important;
}

.content-info .top span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 150%;
  margin: 0px 4px 0px 0px;
}

.content-info .top span:nth-child(1),
.content-info .top span:nth-child(5) {
  color: #1c1b1f;
}

.content-info .top span:nth-child(2) {
  color: #25518c;
}

.content-info .top span:nth-child(4) {
  color: #25518c;
}

.content-info .top span:nth-child(6) {
  color: #25518c;
}

.content-info .bottom h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  color: #1c1b1f;
}

.content-info .more-info,
.content-info .more-info span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 150%;
  color: #1c1b1f;
  margin: 0px 10px 0px 0px;
}

.content-info .more-info span svg {
  margin: 0px 1.25px;
}
section.lib-pagination {
  padding: 10px 0px 80px;
}
.lib-pagination-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.pagination-boxes .pagination {
  width: fit-content;
  margin: auto;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 40px 0px 20px;
}

.pagination-boxes .pagination .page {
  width: 40px;
  height: 40px;
  background: #eff1fb;
  margin: 0px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.pagination-boxes .pagination .page span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #1c1b1f;
  cursor: pointer;
}

.pagination-boxes .pagination .page.more {
  width: 82px;
}
/* == || LIBRARY == */

/* == EVENTS == */
section.filter {
  padding: 80px 0px;
  background: #fcfcfc;
}

.filter-top .head {
  padding: 10px 0px;
}

.filter-top .head h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #1c1b1f;
}

.filter-form,
.filter-form form {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.filter-form form select,
.filter-form form input {
  width: 190px;
  height: 40px;
  border: 1px solid #e0e2f1;
  background-color: #ffffff;
  margin: 10px 20px 10px 0px;
  padding: 5px 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #9a9a9a;
}

.filter-form form select option {
  width: 190px;
  height: 40px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter-form form button {
  border: 0;
  outline: none;
  width: 190px;
  height: 40px;
  background: #25518c;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #fcfcfc;
}

.tab-btns {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 40px 0px 20px;
}

.tab-btns button {
  background-color: #ffffff;
  padding: 18px 30px;
  outline: none;
  height: 60px;
  width: fit-content;
  border: 2px solid #25518c;
  margin: 10px 40px 10px 0px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #25518c;
  cursor: pointer;
}

.tab-btns button:hover,
.tab-btns button.active {
  border: 2px solid #de4404 !important;
  color: #de4404 !important;
  transition: all 100ms !important;
}

.tab-slide {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.tab-card {
  background: #ffffff;
  /* shadow */
  box-shadow: 10px 32px 72px rgba(51, 51, 51, 0.04);
  border-radius: 5px;
  height: 100%;
  max-height: 442px;
  min-height: 278px;
  width: fit-content;
  max-width: 384px;
  min-width: 260px;
  padding: 20px;
  position: relative;
  margin: 10px 5px;
}

.tab-card img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.tab-card .floating-card {
  position: absolute;
  top: 40px;
  right: 40px;
  height: fit-content;
  width: 80%;
  padding: 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.floating-card .hold {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.floating-card span.price {
  height: 40px;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #25518c;
}

.floating-card span.share,
.floating-card span.love {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin: 0px 8px;
  color: #1c1b1f;
}

.floating-card span i {
  font-size: 20px;
  color: #1c1b1f;
}

.tab-card-info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 0px;
}

.tab-card-info .left {
  padding: 10px 0px 0px;
}

.tab-card-info .left span:nth-child(1) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #1c1b1f;
}

.tab-card-info .left span:nth-child(3) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: #1c1b1f;
}

.tab-card-info .right {
  padding: 0px 0px 0px 20px;
}

.tab-card-info .right a {
  text-decoration: none;
  cursor: pointer;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #25518c;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.tab-card-info .right span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1c1b1f;
}

.filter-top .pagination {
  width: fit-content;
  margin: auto;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 40px 0px 20px;
}

.filter-top .pagination .page {
  width: 40px;
  height: 40px;
  background: #eff1fb;
  margin: 0px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.filter-top .pagination .page span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #1c1b1f;
  cursor: pointer;
}

.filter-top .pagination .page.more {
  width: 82px;
}

/* == || EVENTS == */

/* == || COMING SOON == */
.coming-soon {
  background-image: url(../public//images/coming-soon.png);
  background-position: center center;
  height: calc(100vh - 220px);
  background-size: cover;
}
.coming-soon button {
  display: flex;
  align-items: flex-start;
  padding: 13px 221px;
  gap: 10px;
  width: 620px;
  height: 50px;

  background: #25518c;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  color: #fcfcfc;
}
.coming-soon-footer {
  background: #25518c;
}
.coming-soon-footer p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */

  color: #fcfcfc;
}
/* == || COMING SOON == */

/* == || OUTREACH PROGRAMS== */
.outreach-card {
  box-sizing: border-box;

  background: #fcfcfc;
  border: 1px solid rgba(28, 27, 31, 0.05);
  box-shadow: 0px 3px 20px rgba(2, 2, 2, 0.1);
}
.outreach-card img {
  width: 100%;
  object-fit: cover;
}
.outreach-card h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;
  /* identical to box height, or 36px */

  text-align: center;

  color: #000000;
}
.outreach-card p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* or 24px */

  text-align: center;

  color: #000000;
}
.outreach-card button {
  width: 100%;
  height: 40px;
  /* K.A.I.C.T. Blue */
  background: #25518c;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  text-align: center;

  /* K.A.I.C.T. White */

  color: #fcfcfc;
}
.program-form {
  background: rgba(37, 81, 140, 0.9);
  box-shadow: 0px 3px 20px rgba(2, 2, 2, 0.1);
  height: 527px;
  padding: 40px;
}
.program-form h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 37px;
  color: #fcfcfc;
}
.program-form .control-group {
  width: 100% !important;
  max-width: 100% !important;
}
.program-form select {
  box-sizing: border-box;
  height: 50px;
  width: 100%;
  background-color: transparent;
  border: 1px solid #e0e2f1;
  padding-left: 26px;
}
.program-form label {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* K.A.I.C.T. White */

  color: #fcfcfc !important;
}
.program-form input[type="checkbox"] {
  box-sizing: border-box;
  width: 18px;
  height: 18px;

  border: 2px solid #e0e2f1;
}
.program-form p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #fcfcfc;
}
.program-form button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 8px;
  height: 50px;
  width: 100%;

  /* K.A.I.C.T. White */

  background: #fcfcfc;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  /* identical to box height, or 27px */

  /* K.A.I.C.T. Blue */

  color: #25518c;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
/* == || OUTREACH PROGRAMS== */

/* == || RESEARCH PROGRAMS== */

.research-one .stats p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  /* identical to box height, or 36px */

  text-align: center;

  /* K.A.I.C.T. Blue */

  color: #25518c;
}
.research-one .stats span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 49px;
  /* identical to box height */

  text-align: center;

  /* K.A.I.C.T. Blue */

  color: #25518c;
}
.research-one .dots-not {
  width: 10px;
  height: 10px;
  /* K.A.I.C.T. Blue */
  background: #9a9a9a;
}
.research-one .dots-active {
  background: #25518c;
  width: 10px;
  height: 10px;
}
.research-one h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 150%;
  /* identical to box height, or 60px */

  text-align: center;

  color: #000000;
}
.research-header{
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 150%;
  /* identical to box height, or 60px */

  text-align: center;

  color: #000000;

}
.all-research hr {
  border: 1px solid #9a9a9a;
  height: 0px;
  width: 100%;
}
.all-research h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 49px;
  color: #1c1b1f;
}
.all-research .card {
  width: 1240px;
  height: 620px;
  background: linear-gradient(
    0deg,
    rgba(28, 27, 31, 0.2),
    rgba(28, 27, 31, 0.2)
  );
}

.all-research .card-inner {
  width: 620px;
  height: 310px;
  /* K.A.I.C.T. - Light Blue */

  background: #eff1fb;
  padding: 40px;
}
.all-research .card-inner h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;
  /* or 36px */

  color: #000000;
}
.all-research .card-inner p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* or 24px */

  color: #000000;
}
.all-research .card-inner span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  text-align: center;

  color: #25518c;
}
/* == || RESEARCH PROGRAMS== */

/* == OUTREACH == */
/* section.outreach-banner {
  background-image: url('img/outreach\ banner.png') !important;
} */

.outreach-card-sec {
  padding: 5px 0px;
  background: #fcfcfc;
}

.outreach-card-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.outreach-card-container.form {
  justify-content: flex-start;

}

.outreach-card {
  height: fit-content;
  width: 100%;
  max-width: 380px;
  min-width: 280px;
  background: #fcfcfc;
  border: 1px solid rgba(28, 27, 31, 0.05);
  box-shadow: 0px 3px 20px rgba(2, 2, 2, 0.1);
  margin-bottom: 20px;
}

.outreach-card img {
  vertical-align: middle;
  width: 100%;
  height: 300px;

}

.outreach-info {
  padding: 20px 30px;
}

.outreach-info h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  text-align: center;
  color: #000000;
  margin: 0px 0px 10px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  word-wrap: break-word; /* Ensures that long words are wrapped to fit within the container */
  height: 60px;
}

.outreach-info p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #000000;
  margin: 10px;
}

.outreach-info button {
  outline: none;
  border: 0px;
  margin: 10px 0px 0px;
  width: 100%;
  max-width: 340px;
  height: 40px;
  background: #25518c;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #fcfcfc;
}

.outreach-form {
  min-height: 560px;
  height: fit-content;
  width: 100%;
  min-width: 280px;
  max-width: 812px;
  background: rgba(37, 81, 140, 0.9);
  box-shadow: 0px 3px 20px rgba(2, 2, 2, 0.1);
}

.outreach-form form {
  padding: 40px;
  height: 100%;
  width: 100%;
}

h2.out-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 37px;
  margin: 0px 0px 30px;
  color: #fcfcfc;
}

form.outreach label {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #fcfcfc;
}

form.outreach select,
form.outreach option,
form.outreach input {
  max-width: 731px;
  width: 100%;
  height: 50px;
  border: 1px solid #e0e2f1;
  padding: 12px 20px;
  background-color: rgba(37, 81, 140, 0.9);
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #fcfcfc;
  border-radius: 0;
}

form.outreach button {
  width: 100%;
  height: 50px;
  background: #fcfcfc;
  outline: none;
  border: 0px;
  border-radius: 0px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  color: #25518c;
}

form.outreach .control-group {
  width: 100% !important;
  max-width: 800px;
  padding: 10px 0px !important;
}

form.outreach .control-group input::placeholder,
form.outreach .control-group select {
  color: #d9d9d9;
}

form.outreach .control-group.checkbox {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  padding: 10px 10px 10px 35px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

form.outreach .control-group.checkbox label {
  font-size: 12px;
  line-height: 18px;
  margin: 0px 0px 0px 20px;
  color: #ffffff !important;
}

/* Hide the browser's default checkbox */
form.outreach .control-group.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
form.outreach .control-group.checkbox .checkmark {
  position: absolute;
  top: 15px;
  left: 10px;
  height: 18px;
  width: 18px;
  background: rgba(37, 82, 140, 0);
  border: 2px solid #ffffff;
}

/* On mouse-over, add a grey background color */
form.outreach
  .control-group.checkbox
  .container-check:hover
  input
  ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
form.outreach
  .control-group.checkbox
  .container-check
  input:checked
  ~ .checkmark {
  background: rgba(37, 82, 140, 0);
}

/* Create the checkmark/indicator (hidden when not checked) */
form.outreach .control-group.checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
form.outreach
  .control-group.checkbox
  .container-check
  input:checked
  ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
form.outreach .control-group.checkbox .container-check .checkmark:after {
  left: 4px;
  top: 1px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* == OUTREACH == */

/* == SINGLE OUTREACH == */
/* section.outreach-single-banner {
  background-image: url('img/single\ outreach\ banner.png') !important;
} */

section.single {
  padding: 60px 0px;
}

.single-outreach-container p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
  margin: 0px 0px;
}

.single-outreach-cards {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.singe-out-card {
  margin: 20px;
  height: fit-content;
  padding: 10px;
  text-align: center;
  width: 100%;
  max-width: 380px;
  min-width: 260px;
}

.singe-out-card span:nth-child(1) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 49px;
  text-align: center;
  color: #25518c;
}

.singe-out-card span:nth-child(3) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  text-align: center;
  color: #25518c;
}

.outreach-card-container.title {
  text-align: center !important;
}

.single-gallery-slider {
  padding: 20px 0px;
}

.top-gallery {
  display: flex;
  align-items: center;
  justify-content: center;
}

.top-gallery button {
  outline: none;
  border: 0;
  border-radius: 0;
  padding: 10px;
  background-color: #00000000;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.top-gallery button svg {
  pointer-events: none;
}

.top-gallery .slides {
  display: flex;
  align-items: center;
  justify-content: center;
}

.single-out-slide {
  margin: 0px 6px;
}

.single-out-slide img {
  height: 250px;
  width: 375px;
}

.bottom-gallery {
  padding: 20px 0px;
}

.bottom-gallery .dots {
  width: fit-content;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottom-gallery .dots .dot {
  height: 10px;
  width: 10px;
  border-radius: 5px;
  background-color: #9a9a9a;
  margin: 5px;
}

.bottom-gallery .dots .dot.active {
  background-color: #25518c;
}

/* section.single-support {
  background-image: url('img/support\ image.png') !important;
} */

.banner-mask.support {
  padding: 80px;
}

.support-form {
  padding: 40px;
  width: 100%;
  min-width: 280px;
  max-width: 530px;
  min-height: 387px;
  background: rgba(37, 81, 140, 0.9);
  box-shadow: 0px 3px 20px rgba(2, 2, 2, 0.1);
}

.support-form form {
  height: 100%;
  width: 100%;
}

h3.support-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 37px;
  margin: 0px 0px 30px;
  color: #fcfcfc;
}

form.support label {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #fcfcfc;
}

form.support input {
  width: 100%;
  height: 50px;
  border: 1px solid #e0e2f1;
  padding: 12px 20px;
  background-color: rgba(37, 82, 140, 0);
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #fcfcfc;
  border-radius: 0;
}

form.support button {
  width: 100%;
  height: 50px;
  background: #fcfcfc;
  outline: none;
  border: 0px;
  border-radius: 0px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  color: #25518c;
}

form.support .control-group {
  width: 100% !important;
  max-width: 800px;
  padding: 10px 0px !important;
}

form.support .control-group input::placeholder,
form.support .control-group select {
  color: #d9d9d9;
}

form.support .control-group.checkbox {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  padding: 10px 10px 10px 35px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

form.support .control-group.checkbox label {
  font-size: 12px;
  line-height: 18px;
  margin: 0px 0px 0px 20px;
  color: #ffffff !important;
}

/* Hide the browser's default checkbox */
form.support .control-group.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
form.support .control-group.checkbox .checkmark {
  position: absolute;
  top: 15px;
  left: 10px;
  height: 18px;
  width: 18px;
  background: rgba(37, 82, 140, 0);
  border: 2px solid #ffffff;
}

/* On mouse-over, add a grey background color */
form.support .control-group.checkbox .container-check:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
form.support
  .control-group.checkbox
  .container-check
  input:checked
  ~ .checkmark {
  background: rgba(37, 82, 140, 0);
}

/* Create the checkmark/indicator (hidden when not checked) */
form.support .control-group.checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
form.support
  .control-group.checkbox
  .container-check
  input:checked
  ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
form.support .control-group.checkbox .container-check .checkmark:after {
  left: 4px;
  top: 1px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.single-support-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

h2.outreach-single-title {
  margin: 20px 0px 35px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 150%;
  text-align: center !important;
  color: #000000;
  width: 100%;
}

/* == || SINGLE OUTREACH == */

/* == RESEARCH == */
/* section.research-banner {
  background-image: url('img/research.png') !important;
} */

.research-content {
  padding: 60px 0px;
}

.research-head {
  padding: 20px 0px;
  border-bottom: 1px solid #9a9a9a;
}

.research-head h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 49px;
  color: #1c1b1f;
}

.research-information {
  margin: 40px 0px;
  min-height: 620px;
  max-width: 1240px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

/* .research-information.one {
  background-image: url(../public//images/outreach-program/r-1.png);
} */
/*
.research-information.two {
  background-image: url(../public//images/outreach-program/r-2.png);
}

.research-information.three {
  background-image: url(../public//images/outreach-program/r-3.png);
} */

.research-card {
  max-width: 620px;
  min-width: 280px;
  min-height: 310px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  background: #eff1fb;
  padding: 40px;
}

.research-status {
  position: absolute;
  top: 40px;
  right: 40px;
  width: fit-content;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #25518c;
}

.research-status p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #fcfcfc;
}

.research-card h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;
  color: #000000;
}

.research-card p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
  margin: 20px 0px;
}

.research-card a {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #25518c;
}
/* == || RESEARCH == */

/* == SINGLE RESEARCH == */
/* section.single-research-banner {
  background-image: url('img/single\ research.png') !important;
} */

h1.small-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 40px !important;
  line-height: 49px !important;
  color: #fcfcfc;
}

section.single-research {
  padding: 60px 0px;
  background: #eff1fb;
}

.single-research-head {
  padding: 20px 0px 0px;
}
.team-content.research {
  justify-content: space-between !important;
}
.single-research-head h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 37px;
  color: #25518c;
}

.single-research-text {
  padding: 0px 0px 40px;
}

.single-research-text p {
  margin: 20px 0px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1c1b1f;
}



.single-research-card {
  width: 100%;
  max-width: 390px;
  min-width: 280px;
  height: 300px;
  margin: 10px;
  padding: 40px;
  background-color: #25518c;
}

.single-research-card h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #fcfcfc;
}

.single-research-card p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #fcfcfc;
  margin: 20px 0px 10px;
}

.method-cards {
  padding: 20px 0px;
}
.my-ol-list ol{
  list-style: disc !important;
}
.methods {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.method-card {
  width: 50%;
  min-width: 280px;
  height: fit-content;
  padding: 20px;
  background: #fcfcfc;
}

.method-card h4 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: #1c1b1f;
}

.method-card p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #1c1b1f;
  margin: 20px 0px 0px;
}

.method-height {
  height: 147px;
  width: 50%;
  min-width: 280px;
}
.black-events.events {
  justify-content: space-between !important;
}
.single-outcome {
  align-items: flex-start;
  justify-content: space-between;
  padding: 60px 0px;
}
.single-research-text.listing {
  max-width: 620px;
  min-width: 280px;
  width: 100%;
}
.single-outcome .right {
  max-width: 580px;
  min-width: 280px;
  width: 100%;
}
.single-outcome .right img {
  width: 100%;
}
.single-listing {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px 0px;
}
.single-listing p.listing {
  margin: 0px 0px 0px 20px;
}
.publications-research {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 20px 0px;
}
.publications-research .content-info {
  background-color: #ffffff !important;
  padding: 20px;
  position: relative;
  width: 100%;
  max-width: 380px;
  min-width: 280px;
  margin: 10px;
}

.publications-research .content-info .year {
  position: absolute;
  width: 77px;
  height: 44px;
  right: 20px;
  top: -22px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #25518c;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #fcfcfc;
}

.publications-research .content-info .top {
  padding: 0px 0px 10px;
}

.publications-research .content-info .bottom {
  padding: 10px 0px 0px;
}

.publications-research .content-info .top span.no-margin {
  margin: 0px !important;
}

.publications-research .content-info .top span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 150%;
  margin: 0px 4px 0px 0px;
}

.publications-research .content-info .top span:nth-child(1),
.publications-research .content-info .top span:nth-child(5) {
  color: #1c1b1f;
}

.publications-research .content-info .top span:nth-child(2) {
  color: #25518c;
}

.publications-research .content-info .top span:nth-child(4) {
  color: #25518c;
}

.publications-research .content-info .top span:nth-child(6) {
  color: #25518c;
}

.publications-research .content-info .bottom h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  color: #1c1b1f;
}

.publications-research .content-info .more-info,
.publications-research .content-info .more-info span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 150%;
  color: #1c1b1f;
  margin: 0px 10px 0px 0px;
}

.publications-research .content-info .more-info span svg {
  margin: 0px 1.25px;
}
/* == || SINGLE RESEARCH == */

/* == EVENTS == */
section.filter {
  padding: 80px 0px;
  background: #fcfcfc;
}

.filter-top .head {
  padding: 10px 0px;
}

.filter-top .head h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #1c1b1f;
}

.filter-form,
.filter-form form {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.filter-form form select,
.filter-form form input {
  width: 190px;
  height: 40px;
  border: 1px solid #e0e2f1;
  background-color: #ffffff;
  margin: 10px 20px 10px 0px;
  padding: 5px 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #9a9a9a;
}

.filter-form form select option {
  width: 190px;
  height: 40px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter-form form button {
  border: 0;
  outline: none;
  width: 190px;
  height: 40px;
  background: #25518c;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #fcfcfc;
}

.tab-btns {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 3px 0px 10px;
}

.tab-btns button {
  background-color: #ffffff;
  padding: 18px 30px;
  outline: none;
  height: 60px;
  width: fit-content;
  border: 2px solid #25518c;
  margin: 10px 40px 10px 0px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #25518c;
  cursor: pointer;
}

.tab-btns button:hover,
.tab-btns button.active {
  border: 2px solid #de4404 !important;
  color: #de4404 !important;
  transition: all 100ms !important;
}

.tab-slide {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.tab-card {
  background: #ffffff;
  /* shadow */
  box-shadow: 10px 32px 72px rgba(51, 51, 51, 0.04);
  border-radius: 5px;
  height: 422px;
  min-height: 278px;
  width: fit-content;
  max-width: 384px;
  min-width: 260px;
  padding: 20px;
  position: relative;
  margin: 10px 5px;
}

.tab-card img {
  width: 100%;
}

.tab-card .floating-card {
  position: absolute;
  top: 40px;
  right: 40px;
  height: fit-content;
  width: 80%;
  padding: 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.floating-card .hold {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.floating-card span.price {
  height: 40px;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #25518c;
}

.floating-card span.share,
.floating-card span.love {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin: 0px 8px;
  color: #1c1b1f;
}

.floating-card span i {
  font-size: 20px;
  color: #1c1b1f;
}

.tab-card-info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 0px;
}

.tab-card-info .left {
  padding: 10px 0px 0px;
}

.tab-card-info .left span:nth-child(1) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #1c1b1f;
}

.tab-card-info .left span:nth-child(3) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: #1c1b1f;
}

.tab-card-info .right {
  padding: 0px 0px 0px 20px;
}

.tab-card-info .right a {
  text-decoration: none;
  cursor: pointer;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #25518c;
}

.tab-card-info .right span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1c1b1f;
}

.filter-top .pagination {
  width: fit-content;
  margin: auto;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 40px 0px 20px;
}

.filter-top .pagination .page {
  width: 40px;
  height: 40px;
  background: #eff1fb;
  margin: 0px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.filter-top .pagination .page span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #1c1b1f;
  cursor: pointer;
}

.filter-top .pagination .page.more {
  width: 82px;
}
.team-modal h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 49px;
  /* identical to box height */

  text-align: center;

  color: #fcfcfc;
}
.team-modal p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  color: #1c1b1f;
}
.modal-member h3{
  font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 30px;
/* or 150% */

text-align: center;

color: #25518C;
}
.modal-member span{
  font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 150%;
/* identical to box height, or 24px */

text-align: center;

color: #1C1B1F;
}

/* == THINK TANK SINGLE == */
.think-single {
  padding: 10px 0px;
}

h2.think-head {
  margin: 10px 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 37px;
  color: #25518C;

}

p.think-p {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1C1B1F;
  margin: 10px 0px;
}

.takeaway-cards {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.takeaway-card {
  height: 400px;
  width: 400px;
  border-radius: 50% !important;
  background: #EFF1FB;
  text-align: center;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 10px 60px;

}

.card-center h2 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 150%;
  text-align: center;
  color: #1C1B1F;
  margin: 20px 0px;
  width: 100%;
  max-width: 280px;
}

.card-center p {
  width: 100%;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #00000A;
}

.think-head {
  color: #25518C;
}

.comm-engage {
  padding: 10px 0px;
}

.comm-row {
  align-items: center;
  justify-content: space-between;
  margin: 20px 0px;
}

.comm-card {
  width: 100%;
  min-width: 280px;
  max-width: 600px;
  min-height: 147px;
  background: #EFF1FB;
  padding: 20px;
}

.comm-card h2 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: #1C1B1F;
  margin: 0px 0px 20px;
}

.comm-card p {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #1C1B1F;
}

.lecture-cards {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 20px 0px;
}

.lecture-card {
  width: 100%;
  min-width: 280px;
  max-width: 390px;
  position: relative;
}

.lecture-card img {
  position: relative;
  height: 100%;
  vertical-align: middle;
}

.lecture-card button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #00000000;
  cursor: pointer;
}

.lecture-card button svg {
  pointer-events: none;
}

p.title-list {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1C1B1F;
}

ul.think-list {
  list-style-type: disc !important;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1C1B1F;
  padding: 0px 0px 0px 20px;
  margin: 0px 0px 0px 10px;
}
ul.think-list li{
  list-style-type: disc !important;
}

/* == || THINK TANK SINGLE == */





/* == PUBLICATIONS == */
.filter-top .head.publication{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.filter-top span{
  font-family: Montserrat;
font-style: Regular;
font-size: 16px;
line-height: 20px;
}
.filter-top .pagination-numbers{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 35px 0px;
}
.filter-top button{
  background-color: #00000000;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.published-container {
  padding: 40px 40px;
}

.published-card {
  height: fit-content;
  min-height: 324px;
  width: 100%;
  max-width: 390px;
  min-width: 280px;
}

.published-card img {
  width: 100%;
}

.pub-info {
  padding: 20px;
}

.pub-info h3 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #1C1B1F;
}

.pub-info span {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: #1C1B1F;
}

.pub-info span u {
  color: #25518C;
}

section.pub-pagination {
  padding: 10px 0px 10px;
}

.pub-pagination-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 30px;
}

.pub-pagination .pagination-boxes {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pub-pagination .pagination-boxes .pagination {
  width: fit-content;
  margin: auto;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 0px 0px 60px;
}

.pub-pagination .pagination-boxes .pagination .page {
  width: 40px;
  height: 40px;
  background: #EFF1FB;
  margin: 0px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.pub-pagination .pagination-boxes .pagination .page span {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #1C1B1F;
  cursor: pointer;
}

.pub-pagination .pagination-boxes .pagination .page.more {
  width: 82px;
}

/* == PUBLICATIONS == */





/* == RESPONSIVE == */
/* Medium devices (landscape tablets, 768px and up) */
@media screen and (max-width: 994px) {

  header {
      display: none;
  }

  nav {
      height: 120px;
  }

  .nav.logo {
      display: block;
      background: #ffffff;
  }

  .menu-btn {
      color: #1C1B1F;
  }

  .links {
      height: 100vh;
      width: 100%;
      position: fixed;
      top: 0;
      right: -100%;

      display: block;
      padding: 80px 0px 0px;
      text-align: centers;
      transition: all ease 0.5s;

      z-index: 5;
      background-color: #ffffff;
      overflow: auto;
  }

  .links a {
      display: flex;
      align-items: center;
      justify-content: start;
      width: 100% !important;
      height: 50px;
      color: #1C1B1F;
      padding: 0px 50px 0px;
  }

  .links a:hover {
      background-color: #DE4404;
      color: #ffffff;
  }

  .links span {
      cursor: pointer;
  }

  .links.active {
      right: 0%;
  }

  .cancel-btn {
      height: 40px;
      width: 40px;
      border-radius: 20px;
      box-shadow: 2px 2px 12px #0e315533;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #1C1B1F;
      font-size: 18px;
      position: absolute;
      top: 12px;
      right: 20px;
  }

  .banner-text h1 {
      font-size: 40px;
      line-height: 50px;
  }

  .banner-text p {
      font-size: 20px;
      line-height: 30px;
  }

  .banner-text button {
      width: 90%;
      max-width: 320px;
  }

  .upcoming-container {
      justify-content: space-evenly;
  }

  .up-left .top h1 {
      font-size: 25px;
      line-height: 45px;
  }

  .up-left .bottom {
      justify-content: space-evenly;
  }

  .bottom-more button {
      width: 100%;
      max-width: 384px;
      min-width: 280px;
  }

  .tab-slide {
      justify-content: space-evenly;
  }
}

/* Extra small devices (phones, 600px and down) */
@media screen and (max-width: 640px) {
  .banner-text h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    text-align: center;
    color: #fcfcfc;
    font-size: 28px !important;
    line-height: 150% !important;
  }

  .banner-text p {
      font-size: 16px;
      line-height: 33px;
  }

  .banner-text button {
      width: 100%;
      max-width: 384px;
  }

  .up-left .top h1 {
      font-size: 30px;
      line-height: 35px;
  }

  .bottom .up-card {
      max-width: 320px;
  }

  .bottom-more button {
      width: 90%;
      max-width: 320px;
      min-width: 250px;
  }

  .tab-slide {
      justify-content: space-evenly !important;
  }
  .team-content .member {
    height: fit-content;
    width: 350px;
    margin: 10px 0px;
  }
  .method-card {
    width: 100%;
    min-width: 280px;
    height: fit-content;
    padding: 20px;
    background: #fcfcfc;
    margin-bottom: 10px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .links {
      padding: 0px 20px;
      display: block;
      display: flex;
      align-items: center !important;
      justify-content: space-between;
  }

  nav {
      height: 50px;
      background: #25518C;
  }

  .menu-btn {
      color: #ffffff;
  }

  .nav.logo {
      display: none;
  }

  .menu-bar {
      display: none !important;
  }

  .cancel {
      display: none;
  }

  .banner-text h1 {
      font-size: 60px;
      line-height: 90px;
  }

  .banner-text p {
      font-size: 24px;
      line-height: 36px;
  }

  .banner-text button {
      width: 100%;
      max-width: 384px;
  }

  .up-left .top h1 {
      font-size: 40px;
      line-height: 60px;
  }

  .bottom-more button {
      width: 100%;
      max-width: 384px;
      min-width: 280px;
  }

  form .control-group.name {
      width: 100%;
      max-width: 280px;
  }

  .upcoming-container {
      justify-content: space-between;
  }

  .up-left .bottom {
      justify-content: space-between;
  }

  .tab-slide {
      justify-content: space-evenly;
  }
}

/* == || RESPONSIVE == */




/* == RESPONSIVE == */
/* Medium devices (landscape tablets, 768px and up) */
@media screen and (max-width: 994px) {

  header {
      display: none;
  }

  nav {
      height: 120px;
  }

  .nav.logo {
      display: block;
      background: #ffffff;
  }

  .menu-btn {
      color: #1C1B1F;
  }

  .links {
      height: 100vh;
      width: 100%;
      position: fixed;
      top: 0;
      right: -100%;

      display: block;
      padding: 80px 0px 0px;
      text-align: centers;
      transition: all ease 0.5s;

      z-index: 5;
      background-color: #ffffff;
      overflow: auto;
  }

  .links a {
      display: flex;
      align-items: center;
      justify-content: start;
      width: 100% !important;
      height: 50px;
      color: #1C1B1F;
      padding: 0px 50px 0px;
  }

  .links a:hover {
      background-color: #DE4404;
      color: #ffffff;
  }

  .links span {
      cursor: pointer;
  }

  .links.active {
      right: 0%;
  }

  .cancel-btn {
      height: 40px;
      width: 40px;
      border-radius: 20px;
      box-shadow: 2px 2px 12px #0e315533;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #1C1B1F;
      font-size: 18px;
      position: absolute;
      top: 12px;
      right: 20px;
  }

  .banner-text h1 {
      font-size: 40px;
      line-height: 50px;
  }

  .banner-text p {
      font-size: 20px;
      line-height: 30px;
  }

  .banner-text button {
      width: 90%;
      max-width: 320px;
  }

  .upcoming-container {
      justify-content: space-evenly;
  }

  .up-left .top h1 {
      font-size: 25px;
      line-height: 45px;
  }

  .up-left .bottom {
      justify-content: space-evenly;
  }

  .bottom-more button {
      width: 100%;
      max-width: 384px;
      min-width: 280px;
  }

  .tab-slide {
      justify-content: space-evenly;
  }
}

/* Extra small devices (phones, 600px and down) */
@media screen and (max-width: 400px) {
  .banner-text h1 {
      font-size: 30px;
      line-height: 35px;
  }

  .banner-text p {
      font-size: 16px;
      line-height: 33px;
  }

  .banner-text button {
      width: 100%;
      max-width: 384px;
  }

  .up-left .top h1 {
      font-size: 30px;
      line-height: 35px;
  }

  .bottom .up-card {
      max-width: 320px;
  }

  .bottom-more button {
      width: 90%;
      max-width: 320px;
      min-width: 250px;
  }

  .tab-slide {
      justify-content: space-evenly !important;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .links {
      padding: 0px 20px;
      display: block;
      display: flex;
      align-items: center !important;
      justify-content: space-between;
  }

  nav {
      height: 50px;
      background: #25518C;
  }

  .menu-btn {
      color: #ffffff;
  }

  .nav.logo {
      display: none;
  }

  .menu-bar {
      display: none !important;
  }

  .cancel {
      display: none;
  }

  .banner-text h1 {
      font-size: 60px;
      line-height: 90px;
  }

  .banner-text p {
      font-size: 24px;
      line-height: 36px;
  }

  .banner-text button {
      width: 100%;
      max-width: 384px;
  }

  .up-left .top h1 {
      font-size: 40px;
      line-height: 60px;
  }

  .bottom-more button {
      width: 100%;
      max-width: 384px;
      min-width: 280px;
  }

  form .control-group.name {
      width: 100%;
      max-width: 280px;
  }

  .upcoming-container {
      justify-content: space-between;
  }

  .up-left .bottom {
      justify-content: space-between;
  }

  .tab-slide {
      justify-content: space-evenly;
  }
}

/* == || RESPONSIVE == */